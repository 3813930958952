import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				FAQ | Часто Задаваемые Вопросы в Вкусном Уголке – Найдите Ответы на Ваши Вопросы
			</title>
			<meta name={"description"} content={"Ваш компас в мире кулинарных открытий"} />
			<meta property={"og:title"} content={"FAQ | Часто Задаваемые Вопросы в Вкусном Уголке – Найдите Ответы на Ваши Вопросы"} />
			<meta property={"og:description"} content={"Ваш компас в мире кулинарных открытий"} />
			<meta property={"og:image"} content={"https://vurbola.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vurbola.com/img/4762311.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vurbola.com/img/4762311.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vurbola.com/img/4762311.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vurbola.com/img/4762311.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vurbola.com/img/4762311.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vurbola.com/img/4762311.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" max-width="500px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Часто Задаваемые Вопросы
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						В "Вкусном Уголке" мы собрали ответы на самые популярные вопросы, которые могут возникнуть у вас при выборе кулинарных курсов. Здесь вы найдете информацию о начале обучения, необходимом оборудовании, взаимодействии с преподавателями, доступе к курсам, сертификации, специальных диетах, политике возврата и поддерживаемых языках. Эта страница создана, чтобы помочь вам быстро получить ответы и сделать ваш опыт в "Вкусном Уголке" еще более комфортным и приятным. Начните свое кулинарное путешествие с уверенностью, зная, что поддержка и информация всегда под рукой.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Могу ли я начать обучение, не имея опыта в кулинарии?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Конечно! "Вкусный Уголок" приветствует учеников всех уровней подготовки. Мы предлагаем курсы от основ кулинарии до продвинутых техник.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Нужно ли мне покупать специальное оборудование?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Большинство наших курсов разработано так, чтобы вы могли использовать оборудование, которое уже есть на вашей кухне.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Смогу ли я общаться с преподавателями?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Да, наши курсы предусматривают возможность обратной связи и общения с преподавателями для всех учеников.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Как долго я буду иметь доступ к курсу после его покупки?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							После покупки вы получаете пожизненный доступ к курсу и можете возвращаться к нему в любое время.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Могу ли я получить сертификат по окончании курса?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Да, по завершении курса вы получите сертификат, подтверждающий ваши навыки и знания.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Есть ли у вас курсы по специальным диетам, например, веганской или безглютеновой?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Да, мы предлагаем разнообразные курсы, включая приготовление блюд по специальным диетам.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});